import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import data from '../data/expertsData.json';
import 'react-calendar/dist/Calendar.css';
import './ExpertDetail.css';

function ExpertDetail() {
  const { id } = useParams();
  const expert = data.find((expert) => expert.id === parseInt(id));
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  if (!expert) {
    return <div>Expert not found!</div>;
  }

  const handleBooking = () => {
    if (!selectedDate || !selectedTime) {
      alert('Please select a date and time for the appointment.');
      return;
    }
    alert(`Appointment booked with ${expert.name} on ${selectedDate.toDateString()} at ${selectedTime}.`);
  };

  const handleSendMessage = () => {
    alert(`Sending a message to ${expert.name}.`);
  };

  const isDateAvailable = (date) => {
    const dateString = date.toISOString().split('T')[0];
    return expert.availability.dates.includes(dateString);
  };

  return (
    <div className="expert-detail-container">
      <img src={expert.image} alt={expert.name} className="expert-image" />
      <h1>{expert.name}</h1>
      <h2>{expert.category}</h2>
      <p className="expert-bio">{expert.bio}</p>

      <div className="expert-info-section">
        <h3>Details</h3>
        <p>
          <strong>Hourly Rate:</strong> {expert.hourlyRate} {expert.currency}/hour
        </p>
        <p>
          <strong>Accepts:</strong> {expert.accepts.join(', ')}
        </p>
      </div>

      <div className="expert-info-section">
        <h3>Education</h3>
        <ul>
          {expert.education.map((edu, index) => (
            <li key={index}>{edu}</li>
          ))}
        </ul>
      </div>

      <div className="expert-info-section">
        <h3>Certifications</h3>
        <ul>
          {expert.certifications.map((cert, index) => (
            <li key={index}>{cert}</li>
          ))}
        </ul>
      </div>


      <h3>Book an Appointment</h3>
      <Calendar
        value={selectedDate}
        onChange={setSelectedDate}
        tileDisabled={({ date }) => !isDateAvailable(date)}
      />

      {selectedDate && (
        <div className="time-slots">
          <h4>Select a Time</h4>
          {expert.availability.timeSlots.map((time) => (
            <button
              key={time}
              className={`time-slot ${selectedTime === time ? 'selected' : ''}`}
              onClick={() => setSelectedTime(time)}
            >
              {time}
            </button>
          ))}
        </div>
      )}

      <button
        className={`booking-button ${selectedDate && selectedTime ? '' : 'disabled'}`}
        onClick={handleBooking}
        disabled={!selectedDate || !selectedTime}
      >
        Book an Appointment
      </button>

      {/* Send Message Button */}
      <button
        className="send-message-button"
        onClick={handleSendMessage}
      >
        Send Message
      </button>

    </div>
  );
}

export default ExpertDetail;
