import React from 'react';

function About() {
  return (
    <div className="contact-container">
      <h1>About Us</h1>
      <p>If you have any questions, feel free to reach out to us.</p>

    </div>
  );
}

export default About;
