import React from 'react';
import './Main.css';

function Main() {
  return (
    <div className="main-container">
      {/* Hero Bölümü */}
      <section className="hero">
        <h1 className="slogan">Locate your top expert more quickly.</h1>
        <p className="sub-text">Find professionals tailored to your needs in just a few clicks.</p>
      </section>

      {/* Uzmanlık Alanları */}
      <section className="experts">
        <h2>Our Experts</h2>
        <div className="expert-list">
          <div className="expert-item">
            <img src="/images/dietitian.png" alt="Dietitian" />
            <p>Dietitian</p>
          </div>
          <div className="expert-item">
            <img src="/images/naturopath.png" alt="Naturopath" />
            <p>Naturopath</p>
          </div>
          <div className="expert-item">
            <img src="/images/psychologist.png" alt="Psychologist" />
            <p>Psychologist</p>
          </div>
          {/* Diğer uzmanlık alanları burada devam eder */}
        </div>
      </section>
      <section className="patients-signup">
        <h2>Join Us Today</h2>
        <p>
          Sign up now to connect with top experts and receive the care you deserve. It's quick, easy, and secure!
        </p>
        <button className="cta-button">Sign Up</button>
      </section>

      <section className="how-it-works">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <img src="/images/register.png" alt="Register" />
            <h3>Step 1</h3>
            <p>Sign up and create your account.</p>
          </div>
          <div className="step">
            <img src="/images/find-expert.png" alt="Find Expert" />
            <h3>Step 2</h3>
            <p>Find the expert you need.</p>
          </div>
          <div className="step">
            <img src="/images/book-appointment.png" alt="Book Appointment" />
            <h3>Step 3</h3>
            <p>Book an appointment at your convenience.</p>
          </div>
        </div>
      </section>
      <section className="become-an-expert">
        <h2>Join as an Expert</h2>
        <p>
          Are you a professional in your field? Connect with patients and provide the care they need through our platform.
        </p>
        <button className="cta-button">Get Started</button>
      </section>

    </div>
  );
}

export default Main;
