import React from 'react';

function Signup() {
  return (
    <div className="signup-container">
      <h1>Sign Up</h1>
      <p>Welcome! Please fill out the form to create your account.</p>
      {/* Form öğeleri buraya eklenebilir */}
    </div>
  );
}

export default Signup;
