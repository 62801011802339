import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './OurExperts.css';

const expertsData = [
  { id: 1, name: 'Dr. Jane Doe', category: 'Dietitian', image: '/images/dietitian.png' },
  { id: 2, name: 'Dr. John Smith', category: 'Naturopath', image: '/images/naturopath.png' },
  { id: 3, name: 'Dr. Alice Johnson', category: 'Psychologist', image: '/images/psychologist.png' },
  { id: 4, name: 'Dr. Emily Davis', category: 'Psychologist', image: '/images/psychologist.png' },
  { id: 5, name: 'Dr. Michael Brown', category: 'Dietitian', image: '/images/dietitian.png' },
  { id: 6, name: 'Dr. Olivia Green', category: 'Naturopath', image: '/images/naturopath.png' },
  { id: 7, name: 'Dr. Jon Doe', category: 'Dietitian', image: '/images/dietitian.png' },
  { id: 8, name: 'Dr. Elly Smith', category: 'Naturopath', image: '/images/naturopath.png' },
  { id: 9, name: 'Dr. Alex Johnson', category: 'Psychologist', image: '/images/psychologist.png' },
  { id: 10, name: 'Dr. Eric Davis', category: 'Psychologist', image: '/images/psychologist.png' },
  { id: 11, name: 'Dr. George Brown', category: 'Dietitian', image: '/images/dietitian.png' },
  { id: 12, name: 'Dr. Jackie Green', category: 'Naturopath', image: '/images/naturopath.png' },
];

function OurExperts() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredExperts = expertsData.filter(
    (expert) =>
      expert.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      expert.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedExperts = filteredExperts.reduce((groups, expert) => {
    if (!groups[expert.category]) {
      groups[expert.category] = [];
    }
    groups[expert.category].push(expert);
    return groups;
  }, {});

  return (
    <div className="experts-container">
      <h1>Our Experts</h1>
      <input
        type="text"
        placeholder="Search for an expert..."
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="categories">
        {Object.keys(groupedExperts).length > 0 ? (
          Object.keys(groupedExperts).map((category) => (
            <div key={category} className="category-card">
              <h2>{category}</h2>
              <div className="experts-list">
                {groupedExperts[category].map((expert) => (
                  <div key={expert.id} className="expert-item">
                    <img src={expert.image} alt={expert.name} />
                    <h3>{expert.name}</h3>
                    <Link to={`/experts/${expert.id}`} className="details-link">View Details</Link>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No experts found.</p>
        )}
      </div>
    </div>
  );
}

export default OurExperts;
